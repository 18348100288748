import { get as lodashGet } from 'lodash';
import { devtools } from 'zustand/middleware';

import useApiStore from './apiStore';
import { create, storeDevToolOptions } from './storeManager';

type LeadChannelStateComputed = {
  displayLabel: string;
  primaryLogoURL: string;
};

type LeadChannelState = {
  initLeadChannelFinished: boolean;
  leadChannel: Record<string, any>;
  computed: LeadChannelStateComputed;

  initLeadChannel: (
    leadChannelCode: string | null | undefined,
  ) => Promise<void> | undefined;
};

const useLeadChannelStore = create()(
  devtools<LeadChannelState>(
    (set, get) => ({
      initLeadChannelFinished: false,
      leadChannel: {},

      computed: {
        get displayLabel() {
          const leadChannel = get().leadChannel;

          return lodashGet(leadChannel, 'displayLabel', '');
        },
        get primaryLogoURL() {
          const leadChannel = get().leadChannel;

          return lodashGet(leadChannel, 'primaryLogo.url', '');
        },
      },

      initLeadChannel: (leadChannelCode: string | null | undefined) => {
        if (!leadChannelCode) {
          set({ initLeadChannelFinished: true });
          return;
        }

        const radixHttpRequest = useApiStore.getState().radixApi();

        return radixHttpRequest
          .get(`/ui-configuration/lead-channel/${leadChannelCode}`)
          .then((leadChannel) => {
            set({
              leadChannel,
            });
          })
          .finally(() => {
            set({ initLeadChannelFinished: true });
          });
      },
    }),
    storeDevToolOptions('leadChannelStore'),
  ),
);

export default useLeadChannelStore;
