import i18n from 'i18next';
import { concat } from 'lodash';

import {
  LOS_APPLICATION_STATUSES,
  LOS_CANCELLED_STATUSES,
  LOS_COMPLETED_STATUSES,
  LOS_CONTRACTING_STATUSES,
  LOS_FULFILLMENT_STATUSES,
  LOS_FUNDED_STATUSES,
  LOS_LEAD_STATUSES,
  LOS_LEGACY_STATUSES,
} from '../../common';
import useDashboardStore from '../../store/dashboardStore';
import { Task, TaskName } from './applicationTask';

const APPLICANT_TASK_STATUSES: string[] = LOS_LEAD_STATUSES;

const VERIFICATION_TASK_STATUSES: string[] = concat(
  LOS_APPLICATION_STATUSES,
  LOS_CONTRACTING_STATUSES,
  LOS_FULFILLMENT_STATUSES,
  LOS_FUNDED_STATUSES,
);

const COMPLETED_TASK_STATUSES: string[] = LOS_COMPLETED_STATUSES;

const CANCELED_TASK_STATUSES: string[] = concat(
  LOS_CANCELLED_STATUSES,
  LOS_LEGACY_STATUSES,
);

const tasks: Record<TaskName, Task> = {
  [TaskName.APPLICANT]: {
    actionTitle: i18n.t('tasks.applicant.actionTitle'),
    description: i18n.t('tasks.applicant.description'),
    destination: null,
    inactive: false,
    title: i18n.t('tasks.applicant.title'),
    setDestination: (pathname: string) => {
      tasks[TaskName.APPLICANT].destination = pathname;
    },
  },
  [TaskName.VERIFICATIONS]: {
    actionTitle: i18n.t('tasks.verifications.actionTitle'),
    description: i18n.t('tasks.verifications.description'),
    destination: '/verifications',
    inactive: false,
    title: i18n.t('tasks.verifications.title'),
    setDestination: (pathname: string) => {
      tasks[TaskName.VERIFICATIONS].destination = pathname;
    },
  },
  [TaskName.COMPLETE]: {
    actionTitle: i18n.t('tasks.complete.actionTitle'),
    description: i18n.t('tasks.complete.description'),
    destination: '/complete',
    inactive: true,
    title: i18n.t('tasks.complete.title'),
    setDestination: (pathname: string) => {
      tasks[TaskName.COMPLETE].destination = pathname;
    },
  },
  [TaskName.CANCELED]: {
    actionTitle: i18n.t('tasks.canceled.actionTitle'),
    description: i18n.t('tasks.canceled.description'),
    destination: '/apply',
    inactive: false,
    title: i18n.t('tasks.canceled.title'),
    setDestination: (pathname: string) => {
      tasks[TaskName.CANCELED].destination = pathname;
    },
  },
};

export const getTaskByApplicationStatus = (
  applicationStatus: string | null | undefined,
): Task => {
  if (
    !applicationStatus ||
    APPLICANT_TASK_STATUSES.includes(applicationStatus)
  ) {
    const { vehicle } = useDashboardStore.getState();

    const task = tasks[TaskName.APPLICANT];

    task.setDestination(`/applicant/${vehicle?.id}`);

    return task;
  }

  if (VERIFICATION_TASK_STATUSES.includes(applicationStatus)) {
    return tasks[TaskName.VERIFICATIONS];
  }

  if (COMPLETED_TASK_STATUSES.includes(applicationStatus)) {
    return tasks[TaskName.COMPLETE];
  }

  if (CANCELED_TASK_STATUSES.includes(applicationStatus)) {
    return tasks[TaskName.CANCELED];
  }

  return tasks[TaskName.COMPLETE];
};
